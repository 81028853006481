"use client";
import ProfileDevButtons from "@/components/entities/ProfileDevButtons";
import DogfoodEntityFeedback from "@/components/feedback/DogfoodEntityFeedback";
import Layout from "@/components/layout/Layout";
import PageLayout from "@/components/layout/PageLayout";
import EntityStoresProvider from "@/components/providers/EntityStoresProvider";
import EntityTOC from "@/components/sections/EntityTOC";
import ErrorBoundary from "@/components/ui/ErrorBoundary";
import { EntityPageProps } from "@/hooks/useEntityPage";
import { useDevTools, useUI } from "@/hooks/useUIStore";
import EntityContent, { EntityViewType } from "./EntityContent";

export default function EntityLayout(props: EntityPageProps) {
  useUI(props);
  const isDev = useDevTools();

  if (!props.entity) {
    return null;
  }

  return (
    <EntityStoresProvider
      entity={props.entity}
      snapshot={props.snapshot}
      props={{ attributes: props.attributes, aliases: props.aliases, status: props.status }}
    >
      <Layout title={props.entity?.name}>
        <PageLayout outerClassName="bg-gray-50">
          <div className="flex flex-col sm:flex-row gap-4">
            <div>
              <ErrorBoundary>
                <div className="sticky top-4 flex flex-col gap-4 max-h-[calc(100vh-6rem)] overflow-y-auto overflow-x-hidden no-scrollbar">
                  <EntityTOC />
                  {isDev && <ProfileDevButtons />}
                </div>
                {isDev && <DogfoodEntityFeedback visible showMinimizedIcon />}
              </ErrorBoundary>
            </div>

            <div className="flex flex-col flex-1 mb-20 overflow-x-hidden">
              <EntityContent viewType={EntityViewType.Main} />
            </div>
          </div>
        </PageLayout>
      </Layout>
    </EntityStoresProvider>
  );
}
